<script>
// import axios from "axios";
import { SimpleBar } from "simplebar-vue3";

import { authComputed } from "@/state/helpers";
import authSvc from "@/services/auth";
import { toast } from "vue3-toastify";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      username:
        localStorage.getItem("userInfo") !== null
          ? JSON.parse(localStorage.getItem("userInfo")).username
          : "",
      lang: "en",
      text: null,
      flag: null,
      value: null,
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
      ],
      showModalChangePassword: false,
      submitted: false,
      form: {
        password: "",
        confirmPassword: "",
      },
    };
  },
  components: { SimpleBar },
  mounted() {},
  methods: {
    changePassword() {
      this.submitted = false;
      this.form = {
        password: "",
        confirmPassword: "",
      };
      this.showModalChangePassword = true;
    },
    handleChangePassword() {
      this.submitted = true;
      if (
        !this.form.password ||
        !this.form.confirmPassword ||
        this.form.password.length < 6 ||
        !this.form.password !== !this.form.confirmPassword
      ) {
        return;
      }
      const data = {
        username: this.username,
        newPassword: this.form.password,
        confirmNewPassword: this.form.confirmPassword,
      };

      authSvc
        .resetPassword(data)
        .then((resp) => {
          if (resp.status === 200) {
            toast.success("Change Password Successfully", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          } else {
            toast.error("Change Password Failed", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: localStorage.getItem("toastCountdown"),
          });
        })
        .finally(() => {
          this.showModalChangePassword = false;
          this.submitted = false;
          localStorage.removeItem("userInfo");
          localStorage.removeItem("access_token");
          window.location.href = "/login";
        });
      return;
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    logoutUser() {
      localStorage.removeItem("userInfo");
      this.$router.push("/login");
      // eslint-disable-next-line no-unused-vars
      // axios.get("http://127.0.0.1:8000/api/logout").then((res) => {
      //   this.$router.push({
      //     name: "default",
      //   });
      // });
    },
  },
  computed: {
    ...authComputed,
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="17" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo.png" alt height="60" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('navbar.search.text')"
            />
            <span class="bx bx-search-alt"></span>
          </div>
        </form> -->
      </div>
      <div class="d-flex">
        <b-modal
          v-model="showModalChangePassword"
          title="Change Password"
          title-class="text-black font-18"
          body-class="p-3"
          hide-footer
          no-close-on-backdrop
        >
          <form @submit.prevent="handleChangePassword">
            <div class="row">
              <div class="col-12">
                <div class="mb-3">
                  <label for="password">Password</label>
                  <input
                    id="password"
                    v-model="form.password"
                    type="password"
                    :class="`form-control ${
                      submitted && (!form.password || form.password.length < 6)
                        ? 'is-invalid'
                        : ''
                    }`"
                  />
                  <div
                    v-if="
                      submitted && (!form.password || form.password.length < 6)
                    "
                    class="invalid-feedback"
                  >
                    <span v-if="form.password && form.password.length < 6">
                      This field should be at least 6 characters long
                    </span>
                    <span v-else> password is required </span>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <label for="confirmPassword">Confirm Password</label>
                  <input
                    id="confirmPassword"
                    v-model="form.confirmPassword"
                    type="password"
                    :class="`form-control ${
                      submitted &&
                      (!form.confirmPassword ||
                        form.password !== form.confirmPassword)
                        ? 'is-invalid'
                        : ''
                    }`"
                  />

                  <div
                    v-if="
                      submitted &&
                      (!form.confirmPassword ||
                        form.password !== form.confirmPassword)
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        form.confirmPassword &&
                        form.password !== form.confirmPassword
                      "
                    >
                      The value must be equal to the other value
                    </span>
                    <span v-else> confirm password is required </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-end pt-5 mt-3">
              <b-button variant="light" @click="showModalChangePassword = false"
                >Close</b-button
              >
              <b-button type="submit" variant="warning" class="ms-1"
                >Change</b-button
              >
            </div>
          </form>
        </b-modal>
        <SimpleBar style="max-height: 230px"> </SimpleBar>
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-default-icon.png"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ms-1">
              <div>{{ username }}</div>
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <b-dropdown-item
            href="javascript: void(0);"
            @click="changePassword()"
          >
            <i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ $t("navbar.profile.list.changePassword") }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <a @click="logoutUser" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.profile.list.logout") }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
