export const menuItems = [
  {
    id: 1,
    label: "",
    isTitle: true,
  },
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 8,
    label: "menuitems.dashboard.text",
    icon: "bx-bar-chart-alt",
    link: "/",
    roleAdmin: true,
  },
  {
    id: 2,
    label: "menuitems.setting.text",
    icon: "bx-cog",
    badge: {
      variant: "info",
      text: "menuitems.setting.badge",
    },
    subItems: [
      {
        id: 1,
        label: "menuitems.setting.list.field",
        link: "/setting/field",
        parentId: 2,
      },
      {
        id: 2,
        label: "menuitems.setting.list.category",
        link: "/setting/category",
        parentId: 2,
      },
      {
        id: 2,
        label: "menuitems.setting.list.tag",
        link: "/setting/tag",
        parentId: 2,
      },
    ],
  },
  {
    id: 3,
    label: "menuitems.blog.text",
    icon: "bx-list-ul",
    badge: {
      variant: "info",
      text: "",
    },
    subItems: [
      {
        id: 1,
        label: "menuitems.blog.list.list",
        link: "/blog/list",
        parentId: 3,
      },
      {
        id: 2,
        label: "menuitems.blog.list.listEdit",
        link: "/blog/list-edit",
        parentId: 3,
      },
      {
        id: 3,
        label: "menuitems.blog.list.highlight",
        link: "/blog/highlight",
        parentId: 3,
      },
      {
        id: 4,
        label: "menuitems.blog.list.interested",
        link: "/blog/interested",
        parentId: 3,
      },
    ],
  },
  {
    id: 4,
    label: "menuitems.user.text",
    icon: "bx-user",
    link: "/user",
    roleAdmin: true,
  },
  {
    id: 6,
    label: "menuitems.report.text",
    icon: "bxs-file",
    link: "/report",
    roleAdmin: true,
  },
  {
    id: 7,
    label: "menuitems.activity.text",
    icon: "bx-list-ol",
    link: "/activity",
    roleAdmin: true,
  },
];
